import { memo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

function LocationErrorIlustrate({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const WARNING_LIGHT = theme.palette.warning.light;

  return (
    <Box {...other}>
      <svg
        width="478"
        height="480"
        viewBox="0 0 478 480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2535_16852)">
          <path
            d="M421.264 32.8318H300.049C300.049 32.8318 312.074 0 330.564 0C349.053 0 354.852 22.6602 354.852 22.6602C354.852 22.6602 367.329 10.3498 377.583 10.3498C387.838 10.3498 396.75 26.6172 396.75 26.6172C396.75 26.6172 411.235 17.8715 421.264 32.8318Z"
            fill="#EBEBEB"
          />
          <path
            opacity="0.5"
            d="M477.766 67.8619H421.121C421.121 67.8619 433.776 49.5269 443.746 49.5269C453.715 49.5269 456.662 61.7542 456.662 61.7542C456.662 61.7542 463.697 56.0505 470.22 57.0724C476.744 58.0943 477.754 67.8737 477.754 67.8737L477.766 67.8619Z"
            fill="#EBEBEB"
          />
          <path
            opacity="0.5"
            d="M362.885 136.08H306.24C306.24 136.08 318.895 117.745 328.864 117.745C338.834 117.745 341.781 129.973 341.781 129.973C341.781 129.973 348.815 124.269 355.339 125.291C361.863 126.313 362.873 136.092 362.873 136.092L362.885 136.08Z"
            fill="#EBEBEB"
          />
          <path
            d="M110.33 150.173H0C0 150.173 15.1147 116.438 31.2752 114.335C47.4356 112.232 55.1712 139.395 55.1712 139.395C55.1712 139.395 65.5923 129.794 73.7913 129.794C81.9903 129.794 88.3119 142.449 88.3119 142.449C88.3119 142.449 104.71 133.787 110.33 150.185V150.173Z"
            fill="#EBEBEB"
          />
          <path
            opacity="0.5"
            d="M160.725 51.036H86.1968C86.1968 51.036 98.9944 26.5934 112.885 26.2369C126.776 25.8804 134.072 44.7382 134.072 44.7382C134.072 44.7382 155.675 27.4014 160.725 51.036Z"
            fill="#EBEBEB"
          />
          <path
            d="M280.478 167.308H236.18V399.542H280.478V167.308Z"
            fill="#DBDBDB"
          />
          <path
            d="M251.675 174.853H243.06V183.718H251.675V174.853Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 174.853H254.075V183.718H262.69V174.853Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 189.909H243.06V198.773H251.675V189.909Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 189.909H254.075V198.773H262.69V189.909Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 204.964H243.06V213.828H251.675V204.964Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 204.964H254.075V213.828H262.69V204.964Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 220.019H243.06V228.884H251.675V220.019Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 220.019H254.075V228.884H262.69V220.019Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 235.075H243.06V243.939H251.675V235.075Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 235.075H254.075V243.939H262.69V235.075Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 250.13H243.06V258.994H251.675V250.13Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 250.13H254.075V258.994H262.69V250.13Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 265.185H243.06V274.05H251.675V265.185Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 265.185H254.075V274.05H262.69V265.185Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 280.241H243.06V289.105H251.675V280.241Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 280.241H254.075V289.105H262.69V280.241Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 295.296H243.06V304.16H251.675V295.296Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 295.296H254.075V304.16H262.69V295.296Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 310.351H243.06V319.216H251.675V310.351Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 310.351H254.075V319.216H262.69V310.351Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 325.407H243.06V334.271H251.675V325.407Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 325.407H254.075V334.271H262.69V325.407Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 340.462H243.06V349.326H251.675V340.462Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 340.462H254.075V349.326H262.69V340.462Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 355.517H243.06V364.382H251.675V355.517Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 355.517H254.075V364.382H262.69V355.517Z"
            fill="#EBEBEB"
          />
          <path
            d="M251.675 370.573H243.06V379.437H251.675V370.573Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 379.425H273.61V370.561H264.995V379.425Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 364.37H273.61V355.505H264.995V364.37Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 349.315H273.61V340.45H264.995V349.315Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 334.271H273.61V325.407H264.995V334.271Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 319.216H273.61V310.351H264.995V319.216Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 304.16H273.61V295.296H264.995V304.16Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 289.105H273.61V280.241H264.995V289.105Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 274.05H273.61V265.185H264.995V274.05Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 258.994H273.61V250.13H264.995V258.994Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 243.939H273.61V235.075H264.995V243.939Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 228.884H273.61V220.019H264.995V228.884Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 213.828H273.61V204.964H264.995V213.828Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 198.773H273.61V189.909H264.995V198.773Z"
            fill="#EBEBEB"
          />
          <path
            d="M264.995 183.718H273.61V174.853H264.995V183.718Z"
            fill="#EBEBEB"
          />
          <path
            d="M262.69 370.573H254.075V379.437H262.69V370.573Z"
            fill="#EBEBEB"
          />
          <path
            d="M315.425 167.308H280.49V399.542H315.425V167.308Z"
            fill="#A6A6A6"
          />
          <path
            d="M304.695 379.425H311.813V370.561H304.695V379.425Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 379.425H302.723V370.561H295.617V379.425Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 364.37H311.813V355.505H304.695V364.37Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 364.37H302.723V355.505H295.617V364.37Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 349.315H311.813V340.45H304.695V349.315Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 349.315H302.723V340.45H295.617V349.315Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 334.271H311.813V325.407H304.695V334.271Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 334.271H302.723V325.407H295.617V334.271Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 319.216H311.813V310.351H304.695V319.216Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 319.216H302.723V310.351H295.617V319.216Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 304.16H311.813V295.296H304.695V304.16Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 304.16H302.723V295.296H295.617V304.16Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 289.105H311.813V280.241H304.695V289.105Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 289.105H302.723V280.241H295.617V289.105Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 274.05H311.813V265.185H304.695V274.05Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 274.05H302.723V265.185H295.617V274.05Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 258.994H311.813V250.13H304.695V258.994Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 258.994H302.723V250.13H295.617V258.994Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 243.939H311.813V235.075H304.695V243.939Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 243.939H302.723V235.075H295.617V243.939Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 228.884H311.813V220.019H304.695V228.884Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 228.884H302.723V220.019H295.617V228.884Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 213.828H311.813V204.964H304.695V213.828Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 213.828H302.723V204.964H295.617V213.828Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 198.773H311.813V189.909H304.695V198.773Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 198.773H302.723V189.909H295.617V198.773Z"
            fill="#C7C7C7"
          />
          <path
            d="M304.695 183.718H311.813V174.853H304.695V183.718Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 174.853H286.586V183.718H293.692V174.853Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 189.909H286.586V198.773H293.692V189.909Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 204.964H286.586V213.828H293.692V204.964Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 220.019H286.586V228.884H293.692V220.019Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 235.075H286.586V243.939H293.692V235.075Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 250.13H286.586V258.994H293.692V250.13Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 265.185H286.586V274.05H293.692V265.185Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 280.241H286.586V289.105H293.692V280.241Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 295.296H286.586V304.16H293.692V295.296Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 310.351H286.586V319.216H293.692V310.351Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 325.407H286.586V334.271H293.692V325.407Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 340.462H286.586V349.326H293.692V340.462Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 355.517H286.586V364.382H293.692V355.517Z"
            fill="#C7C7C7"
          />
          <path
            d="M293.692 370.573H286.586V379.437H293.692V370.573Z"
            fill="#C7C7C7"
          />
          <path
            d="M295.617 183.718H302.723V174.853H295.617V183.718Z"
            fill="#C7C7C7"
          />
          <path
            d="M121.203 162.246H74.9915V399.543H121.203V162.246Z"
            fill="#EBEBEB"
          />
          <path
            d="M121.203 157.517H73.8745V162.246H121.203V157.517Z"
            fill="#C7C7C7"
          />
          <path
            d="M83.844 169.209H79.1623V399.542H83.844V169.209Z"
            fill="white"
          />
          <path
            d="M92.1381 169.209H87.4564V399.542H92.1381V169.209Z"
            fill="white"
          />
          <path
            d="M100.432 169.209H95.7505V399.542H100.432V169.209Z"
            fill="white"
          />
          <path
            d="M108.726 169.209H104.045V399.542H108.726V169.209Z"
            fill="white"
          />
          <path
            d="M117.02 169.209H112.339V399.542H117.02V169.209Z"
            fill="white"
          />
          <path
            d="M83.844 175.174H79.1623V184.502H83.844V175.174Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 175.174H87.4564V184.502H92.1381V175.174Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 175.174H95.7505V184.502H100.432V175.174Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 175.174H104.045V184.502H108.726V175.174Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 175.174H112.339V184.502H117.02V175.174Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 190.336H79.1623V199.664H83.844V190.336Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 190.336H87.4564V199.664H92.1381V190.336Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 190.336H95.7505V199.664H100.432V190.336Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 190.336H104.045V199.664H108.726V190.336Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 190.336H112.339V199.664H117.02V190.336Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 205.499H79.1623V214.827H83.844V205.499Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 205.499H87.4564V214.827H92.1381V205.499Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 205.499H95.7505V214.827H100.432V205.499Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 205.499H104.045V214.827H108.726V205.499Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 205.499H112.339V214.827H117.02V205.499Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 220.661H79.1623V229.989H83.844V220.661Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 220.661H87.4564V229.989H92.1381V220.661Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 220.661H95.7505V229.989H100.432V220.661Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 220.661H104.045V229.989H108.726V220.661Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 220.661H112.339V229.989H117.02V220.661Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 235.823H79.1623V245.151H83.844V235.823Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 235.823H87.4564V245.151H92.1381V235.823Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 235.823H95.7505V245.151H100.432V235.823Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 235.823H104.045V245.151H108.726V235.823Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 235.823H112.339V245.151H117.02V235.823Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 250.974H79.1623V260.302H83.844V250.974Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 250.974H87.4564V260.302H92.1381V250.974Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 250.974H95.7505V260.302H100.432V250.974Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 250.974H104.045V260.302H108.726V250.974Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 250.974H112.339V260.302H117.02V250.974Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 266.136H79.1623V275.464H83.844V266.136Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 266.136H87.4564V275.464H92.1381V266.136Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 266.136H95.7505V275.464H100.432V266.136Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 266.136H104.045V275.464H108.726V266.136Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 266.136H112.339V275.464H117.02V266.136Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 281.298H79.1623V290.626H83.844V281.298Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 281.298H87.4564V290.626H92.1381V281.298Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 281.298H95.7505V290.626H100.432V281.298Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 281.298H104.045V290.626H108.726V281.298Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 281.298H112.339V290.626H117.02V281.298Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 296.46H79.1623V305.788H83.844V296.46Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 296.46H87.4564V305.788H92.1381V296.46Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 296.46H95.7505V305.788H100.432V296.46Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 296.46H104.045V305.788H108.726V296.46Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 296.46H112.339V305.788H117.02V296.46Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 311.623H79.1623V320.951H83.844V311.623Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 311.623H87.4564V320.951H92.1381V311.623Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 311.623H95.7505V320.951H100.432V311.623Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 311.623H104.045V320.951H108.726V311.623Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 311.623H112.339V320.951H117.02V311.623Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 326.785H79.1623V336.113H83.844V326.785Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 326.785H87.4564V336.113H92.1381V326.785Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 326.785H95.7505V336.113H100.432V326.785Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 326.785H104.045V336.113H108.726V326.785Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 326.785H112.339V336.113H117.02V326.785Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 341.935H79.1623V351.263H83.844V341.935Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 341.935H87.4564V351.263H92.1381V341.935Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 341.935H95.7505V351.263H100.432V341.935Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 341.935H104.045V351.263H108.726V341.935Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 341.935H112.339V351.263H117.02V341.935Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 357.098H79.1623V366.426H83.844V357.098Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 357.098H87.4564V366.426H92.1381V357.098Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 357.098H95.7505V366.426H100.432V357.098Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 357.098H104.045V366.426H108.726V357.098Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 357.098H112.339V366.426H117.02V357.098Z"
            fill="#EBEBEB"
          />
          <path
            d="M83.844 372.26H79.1623V399.542H83.844V372.26Z"
            fill="#EBEBEB"
          />
          <path
            d="M92.1381 372.26H87.4564V399.542H92.1381V372.26Z"
            fill="#EBEBEB"
          />
          <path
            d="M100.432 372.26H95.7505V399.542H100.432V372.26Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.726 372.26H104.045V399.542H108.726V372.26Z"
            fill="#EBEBEB"
          />
          <path
            d="M117.02 372.26H112.339V399.542H117.02V372.26Z"
            fill="#EBEBEB"
          />
          <path
            d="M150.874 162.246H121.203V399.543H150.874V162.246Z"
            fill="#DBDBDB"
          />
          <path
            d="M152.157 157.517H121.203V162.246H152.157V157.517Z"
            fill="#C7C7C7"
          />
          <path
            d="M130.068 169.209H125.386V399.542H130.068V169.209Z"
            fill="#C7C7C7"
          />
          <path
            d="M138.362 169.209H133.68V399.542H138.362V169.209Z"
            fill="#C7C7C7"
          />
          <path
            d="M146.656 169.209H141.974V399.542H146.656V169.209Z"
            fill="#C7C7C7"
          />
          <path
            d="M130.068 175.174H125.386V184.502H130.068V175.174Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 175.174H133.68V184.502H138.362V175.174Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 175.174H141.974V184.502H146.656V175.174Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 190.336H125.386V199.664H130.068V190.336Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 190.336H133.68V199.664H138.362V190.336Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 190.336H141.974V199.664H146.656V190.336Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 205.499H125.386V214.827H130.068V205.499Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 205.499H133.68V214.827H138.362V205.499Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 205.499H141.974V214.827H146.656V205.499Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 220.661H125.386V229.989H130.068V220.661Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 220.661H133.68V229.989H138.362V220.661Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 220.661H141.974V229.989H146.656V220.661Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 235.823H125.386V245.151H130.068V235.823Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 235.823H133.68V245.151H138.362V235.823Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 235.823H141.974V245.151H146.656V235.823Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 250.974H125.386V260.302H130.068V250.974Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 250.974H133.68V260.302H138.362V250.974Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 250.974H141.974V260.302H146.656V250.974Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 266.136H125.386V275.464H130.068V266.136Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 266.136H133.68V275.464H138.362V266.136Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 266.136H141.974V275.464H146.656V266.136Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 281.298H125.386V290.626H130.068V281.298Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 281.298H133.68V290.626H138.362V281.298Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 281.298H141.974V290.626H146.656V281.298Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 296.46H125.386V305.788H130.068V296.46Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 296.46H133.68V305.788H138.362V296.46Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 296.46H141.974V305.788H146.656V296.46Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 311.623H125.386V320.951H130.068V311.623Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 311.623H133.68V320.951H138.362V311.623Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 311.623H141.974V320.951H146.656V311.623Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 326.785H125.386V336.113H130.068V326.785Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 326.785H133.68V336.113H138.362V326.785Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 326.785H141.974V336.113H146.656V326.785Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 341.935H125.386V351.263H130.068V341.935Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 341.935H133.68V351.263H138.362V341.935Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 341.935H141.974V351.263H146.656V341.935Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 357.098H125.386V366.426H130.068V357.098Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 357.098H133.68V366.426H138.362V357.098Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 357.098H141.974V366.426H146.656V357.098Z"
            fill="#DBDBDB"
          />
          <path
            d="M130.068 372.26H125.386V399.542H130.068V372.26Z"
            fill="#DBDBDB"
          />
          <path
            d="M138.362 372.26H133.68V399.542H138.362V372.26Z"
            fill="#DBDBDB"
          />
          <path
            d="M146.656 372.26H141.974V399.542H146.656V372.26Z"
            fill="#DBDBDB"
          />
          <path
            d="M54.1374 233.316H10.3023V399.543H54.1374V233.316Z"
            fill="#EBEBEB"
          />
          <path
            d="M54.1374 222.289H16.5406V233.316H54.1374V222.289Z"
            fill="#C7C7C7"
          />
          <path
            d="M49.3368 239.067H15.1147V363.336H49.3368V239.067Z"
            fill="#DBDBDB"
          />
          <path
            d="M49.3368 250.926H15.1147V253.861H49.3368V250.926Z"
            fill="white"
          />
          <path
            d="M49.3368 263.094H15.1147V266.029H49.3368V263.094Z"
            fill="white"
          />
          <path
            d="M49.3368 275.25H15.1147V278.185H49.3368V275.25Z"
            fill="white"
          />
          <path
            d="M49.3368 287.418H15.1147V290.353H49.3368V287.418Z"
            fill="white"
          />
          <path
            d="M49.3368 299.586H15.1147V302.521H49.3368V299.586Z"
            fill="white"
          />
          <path
            d="M49.3368 311.742H15.1147V314.677H49.3368V311.742Z"
            fill="white"
          />
          <path
            d="M49.3368 323.909H15.1147V326.844H49.3368V323.909Z"
            fill="white"
          />
          <path
            d="M49.3368 336.077H15.1147V339.012H49.3368V336.077Z"
            fill="white"
          />
          <path
            d="M49.3368 348.233H15.1147V351.168H49.3368V348.233Z"
            fill="white"
          />
          <path
            d="M49.3368 360.401H15.1147V363.336H49.3368V360.401Z"
            fill="white"
          />
          <path
            d="M81.5863 233.316H54.1374V399.542H81.5863V233.316Z"
            fill="#DBDBDB"
          />
          <path
            d="M77.4749 222.289H54.1374V233.316H77.4749V222.289Z"
            fill="#A6A6A6"
          />
          <path
            d="M79.0316 239.067H57.9279V363.336H79.0316V239.067Z"
            fill="#C7C7C7"
          />
          <path
            d="M79.0316 250.926H57.9279V253.861H79.0316V250.926Z"
            fill="white"
          />
          <path
            d="M79.0316 263.094H57.9279V266.029H79.0316V263.094Z"
            fill="white"
          />
          <path
            d="M79.0316 275.25H57.9279V278.185H79.0316V275.25Z"
            fill="white"
          />
          <path
            d="M79.0316 287.418H57.9279V290.353H79.0316V287.418Z"
            fill="white"
          />
          <path
            d="M79.0316 299.586H57.9279V302.521H79.0316V299.586Z"
            fill="white"
          />
          <path
            d="M79.0316 311.742H57.9279V314.677H79.0316V311.742Z"
            fill="white"
          />
          <path
            d="M79.0316 323.909H57.9279V326.844H79.0316V323.909Z"
            fill="white"
          />
          <path
            d="M79.0316 336.077H57.9279V339.012H79.0316V336.077Z"
            fill="white"
          />
          <path
            d="M79.0316 348.233H57.9279V351.168H79.0316V348.233Z"
            fill="white"
          />
          <path
            d="M79.0316 360.401H57.9279V363.336H79.0316V360.401Z"
            fill="white"
          />
          <path
            d="M425.363 236.12H401.955V263.771H425.363V236.12Z"
            fill="#DBDBDB"
          />
          <path
            d="M440.205 236.12H425.363V264.793H440.205V236.12Z"
            fill="#C7C7C7"
          />
          <path
            d="M441.203 248.324H426.469V264.781H441.203V248.324Z"
            fill="#DBDBDB"
          />
          <path
            d="M450.376 248.324H441.191V264.781H450.376V248.324Z"
            fill="#C7C7C7"
          />
          <path
            d="M430.841 261.977H392.983V399.554H430.841V261.977Z"
            fill="#EBEBEB"
          />
          <path
            d="M409.215 267.883H397.308V272.743H409.215V267.883Z"
            fill="white"
          />
          <path
            d="M424.437 267.883H412.53V272.743H424.437V267.883Z"
            fill="white"
          />
          <path
            d="M409.215 276.141H397.308V281.001H409.215V276.141Z"
            fill="white"
          />
          <path
            d="M424.437 276.141H412.53V281.001H424.437V276.141Z"
            fill="white"
          />
          <path d="M409.215 284.4H397.308V289.26H409.215V284.4Z" fill="white" />
          <path d="M424.437 284.4H412.53V289.26H424.437V284.4Z" fill="white" />
          <path
            d="M409.215 292.658H397.308V297.518H409.215V292.658Z"
            fill="white"
          />
          <path
            d="M424.437 292.658H412.53V297.518H424.437V292.658Z"
            fill="white"
          />
          <path
            d="M409.215 300.916H397.308V305.776H409.215V300.916Z"
            fill="white"
          />
          <path
            d="M424.437 300.916H412.53V305.776H424.437V300.916Z"
            fill="white"
          />
          <path
            d="M409.215 309.175H397.308V314.035H409.215V309.175Z"
            fill="white"
          />
          <path
            d="M424.437 309.175H412.53V314.035H424.437V309.175Z"
            fill="white"
          />
          <path
            d="M409.215 317.433H397.308V322.293H409.215V317.433Z"
            fill="white"
          />
          <path
            d="M424.437 317.433H412.53V322.293H424.437V317.433Z"
            fill="white"
          />
          <path
            d="M409.215 325.692H397.308V330.552H409.215V325.692Z"
            fill="white"
          />
          <path
            d="M424.437 325.692H412.53V330.552H424.437V325.692Z"
            fill="white"
          />
          <path
            d="M409.215 333.95H397.308V338.81H409.215V333.95Z"
            fill="white"
          />
          <path
            d="M424.437 333.95H412.53V338.81H424.437V333.95Z"
            fill="white"
          />
          <path
            d="M409.215 342.209H397.308V347.069H409.215V342.209Z"
            fill="white"
          />
          <path
            d="M424.437 342.209H412.53V347.069H424.437V342.209Z"
            fill="white"
          />
          <path
            d="M409.215 350.455H397.308V355.315H409.215V350.455Z"
            fill="white"
          />
          <path
            d="M424.437 350.455H412.53V355.315H424.437V350.455Z"
            fill="white"
          />
          <path
            d="M409.215 358.714H397.308V363.574H409.215V358.714Z"
            fill="white"
          />
          <path
            d="M424.437 358.714H412.53V363.574H424.437V358.714Z"
            fill="white"
          />
          <path
            d="M409.215 366.972H397.308V371.832H409.215V366.972Z"
            fill="white"
          />
          <path
            d="M424.437 366.972H412.53V371.832H424.437V366.972Z"
            fill="white"
          />
          <path
            d="M409.215 375.231H397.308V380.091H409.215V375.231Z"
            fill="white"
          />
          <path
            d="M424.437 375.231H412.53V380.091H424.437V375.231Z"
            fill="white"
          />
          <path
            d="M461.499 261.977H430.829V399.554H461.499V261.977Z"
            fill="#C7C7C7"
          />
          <path
            d="M448.665 380.102H458.433V375.242H448.665V380.102Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 380.102H445.932V375.242H436.165V380.102Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 371.844H458.433V366.984H448.665V371.844Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 371.844H445.932V366.984H436.165V371.844Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 363.586H458.433V358.726H448.665V363.586Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 363.586H445.932V358.726H436.165V363.586Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 355.327H458.433V350.467H448.665V355.327Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 355.327H445.932V350.467H436.165V355.327Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 347.057H458.433V342.197H448.665V347.057Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 347.057H445.932V342.197H436.165V347.057Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 338.81H458.433V333.95H448.665V338.81Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 338.81H445.932V333.95H436.165V338.81Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 330.552H458.433V325.692H448.665V330.552Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 330.552H445.932V325.692H436.165V330.552Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 322.293H458.433V317.433H448.665V322.293Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 322.293H445.932V317.433H436.165V322.293Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 314.035H458.433V309.175H448.665V314.035Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 314.035H445.932V309.175H436.165V314.035Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 305.776H458.433V300.916H448.665V305.776Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 305.776H445.932V300.916H436.165V305.776Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 297.518H458.433V292.658H448.665V297.518Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 297.518H445.932V292.658H436.165V297.518Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 289.271H458.433V284.411H448.665V289.271Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 289.271H445.932V284.411H436.165V289.271Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 281.013H458.433V276.153H448.665V281.013Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 281.013H445.932V276.153H436.165V281.013Z"
            fill="#EBEBEB"
          />
          <path
            d="M448.665 272.755H458.433V267.895H448.665V272.755Z"
            fill="#EBEBEB"
          />
          <path
            d="M436.165 272.755H445.932V267.895H436.165V272.755Z"
            fill="#EBEBEB"
          />
          <path
            d="M172.275 236.12H148.866V263.771H172.275V236.12Z"
            fill="#DBDBDB"
          />
          <path
            d="M187.116 236.12H172.275V264.793H187.116V236.12Z"
            fill="#C7C7C7"
          />
          <path
            d="M188.114 248.324H173.38V264.781H188.114V248.324Z"
            fill="#DBDBDB"
          />
          <path
            d="M197.3 248.324H188.114V264.781H197.3V248.324Z"
            fill="#C7C7C7"
          />
          <path
            d="M177.753 261.977H139.895V399.554H177.753V261.977Z"
            fill="#EBEBEB"
          />
          <path
            d="M156.126 267.883H144.22V272.743H156.126V267.883Z"
            fill="white"
          />
          <path
            d="M171.348 267.883H159.441V272.743H171.348V267.883Z"
            fill="white"
          />
          <path
            d="M156.126 276.141H144.22V281.001H156.126V276.141Z"
            fill="white"
          />
          <path
            d="M171.348 276.141H159.441V281.001H171.348V276.141Z"
            fill="white"
          />
          <path d="M156.126 284.4H144.22V289.26H156.126V284.4Z" fill="white" />
          <path d="M171.348 284.4H159.441V289.26H171.348V284.4Z" fill="white" />
          <path
            d="M156.126 292.658H144.22V297.518H156.126V292.658Z"
            fill="white"
          />
          <path
            d="M171.348 292.658H159.441V297.518H171.348V292.658Z"
            fill="white"
          />
          <path
            d="M156.126 300.916H144.22V305.776H156.126V300.916Z"
            fill="white"
          />
          <path
            d="M171.348 300.916H159.441V305.776H171.348V300.916Z"
            fill="white"
          />
          <path
            d="M156.126 309.175H144.22V314.035H156.126V309.175Z"
            fill="white"
          />
          <path
            d="M171.348 309.175H159.441V314.035H171.348V309.175Z"
            fill="white"
          />
          <path
            d="M156.126 317.433H144.22V322.293H156.126V317.433Z"
            fill="white"
          />
          <path
            d="M171.348 317.433H159.441V322.293H171.348V317.433Z"
            fill="white"
          />
          <path
            d="M156.126 325.692H144.22V330.552H156.126V325.692Z"
            fill="white"
          />
          <path
            d="M171.348 325.692H159.441V330.552H171.348V325.692Z"
            fill="white"
          />
          <path
            d="M156.126 333.95H144.22V338.81H156.126V333.95Z"
            fill="white"
          />
          <path
            d="M171.348 333.95H159.441V338.81H171.348V333.95Z"
            fill="white"
          />
          <path
            d="M156.126 342.209H144.22V347.069H156.126V342.209Z"
            fill="white"
          />
          <path
            d="M171.348 342.209H159.441V347.069H171.348V342.209Z"
            fill="white"
          />
          <path
            d="M156.126 350.455H144.22V355.315H156.126V350.455Z"
            fill="white"
          />
          <path
            d="M171.348 350.455H159.441V355.315H171.348V350.455Z"
            fill="white"
          />
          <path
            d="M156.126 358.714H144.22V363.574H156.126V358.714Z"
            fill="white"
          />
          <path
            d="M171.348 358.714H159.441V363.574H171.348V358.714Z"
            fill="white"
          />
          <path
            d="M156.126 366.972H144.22V371.832H156.126V366.972Z"
            fill="white"
          />
          <path
            d="M171.348 366.972H159.441V371.832H171.348V366.972Z"
            fill="white"
          />
          <path
            d="M156.126 375.231H144.22V380.091H156.126V375.231Z"
            fill="white"
          />
          <path
            d="M171.348 375.231H159.441V380.091H171.348V375.231Z"
            fill="white"
          />
          <path
            d="M208.41 261.977H177.741V399.554H208.41V261.977Z"
            fill="#C7C7C7"
          />
          <path
            d="M195.577 380.102H205.344V375.242H195.577V380.102Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 380.102H192.844V375.242H183.076V380.102Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 371.844H205.344V366.984H195.577V371.844Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 371.844H192.844V366.984H183.076V371.844Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 363.586H205.344V358.726H195.577V363.586Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 363.586H192.844V358.726H183.076V363.586Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 355.327H205.344V350.467H195.577V355.327Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 355.327H192.844V350.467H183.076V355.327Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 347.057H205.344V342.197H195.577V347.057Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 347.057H192.844V342.197H183.076V347.057Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 338.81H205.344V333.95H195.577V338.81Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 338.81H192.844V333.95H183.076V338.81Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 330.552H205.344V325.692H195.577V330.552Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 330.552H192.844V325.692H183.076V330.552Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 322.293H205.344V317.433H195.577V322.293Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 322.293H192.844V317.433H183.076V322.293Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 314.035H205.344V309.175H195.577V314.035Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 314.035H192.844V309.175H183.076V314.035Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 305.776H205.344V300.916H195.577V305.776Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 305.776H192.844V300.916H183.076V305.776Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 297.518H205.344V292.658H195.577V297.518Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 297.518H192.844V292.658H183.076V297.518Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 289.271H205.344V284.411H195.577V289.271Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 289.271H192.844V284.411H183.076V289.271Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 281.013H205.344V276.153H195.577V281.013Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 281.013H192.844V276.153H183.076V281.013Z"
            fill="#EBEBEB"
          />
          <path
            d="M195.577 272.755H205.344V267.895H195.577V272.755Z"
            fill="#EBEBEB"
          />
          <path
            d="M183.076 272.755H192.844V267.895H183.076V272.755Z"
            fill="#EBEBEB"
          />
          <path
            d="M238.877 479.988C351.866 479.988 443.461 470.838 443.461 459.55C443.461 448.262 351.866 439.112 238.877 439.112C125.889 439.112 34.2934 448.262 34.2934 459.55C34.2934 470.838 125.889 479.988 238.877 479.988Z"
            fill="#EBEBEB"
          />
          <path
            d="M354.281 309.9H309.638V399.543H354.281V309.9Z"
            fill="#37474F"
          />
          <path
            d="M324.563 317.659H316.661V323.149H324.563V317.659Z"
            fill="#455A64"
          />
          <path
            d="M324.563 329.387H316.661V334.877H324.563V329.387Z"
            fill="#455A64"
          />
          <path
            d="M324.563 341.115H316.661V346.605H324.563V341.115Z"
            fill="#455A64"
          />
          <path
            d="M324.563 352.856H316.661V358.345H324.563V352.856Z"
            fill="#455A64"
          />
          <path
            d="M324.563 364.584H316.661V370.074H324.563V364.584Z"
            fill="#455A64"
          />
          <path
            d="M324.563 376.312H316.661V381.802H324.563V376.312Z"
            fill="#455A64"
          />
          <path
            d="M324.563 388.04H316.661V393.53H324.563V388.04Z"
            fill="#455A64"
          />
          <path
            d="M335.935 317.659H328.033V323.149H335.935V317.659Z"
            fill="#455A64"
          />
          <path
            d="M335.935 329.387H328.033V334.877H335.935V329.387Z"
            fill="#455A64"
          />
          <path
            d="M335.935 341.115H328.033V346.605H335.935V341.115Z"
            fill="#455A64"
          />
          <path
            d="M335.935 352.856H328.033V358.345H335.935V352.856Z"
            fill="#455A64"
          />
          <path
            d="M335.935 364.584H328.033V370.074H335.935V364.584Z"
            fill="#455A64"
          />
          <path
            d="M335.935 376.312H328.033V381.802H335.935V376.312Z"
            fill="#455A64"
          />
          <path
            d="M335.935 388.04H328.033V393.53H335.935V388.04Z"
            fill="#455A64"
          />
          <path
            d="M347.271 317.659H339.369V323.149H347.271V317.659Z"
            fill="#455A64"
          />
          <path
            d="M347.271 329.387H339.369V334.877H347.271V329.387Z"
            fill="#455A64"
          />
          <path
            d="M347.271 341.115H339.369V346.605H347.271V341.115Z"
            fill="#455A64"
          />
          <path
            d="M347.271 352.856H339.369V358.345H347.271V352.856Z"
            fill="#455A64"
          />
          <path
            d="M347.271 364.584H339.369V370.074H347.271V364.584Z"
            fill="#455A64"
          />
          <path
            d="M347.271 376.312H339.369V381.802H347.271V376.312Z"
            fill="#455A64"
          />
          <path
            d="M347.271 388.04H339.369V393.53H347.271V388.04Z"
            fill="#455A64"
          />
          <path
            d="M354.293 300.56H324.563V309.9H354.293V300.56Z"
            fill="#455A64"
          />
          <path
            d="M354.293 294.773H335.447V300.56H354.293V294.773Z"
            fill="#37474F"
          />
          <path
            d="M385.616 309.9H354.293V399.543H385.616V309.9Z"
            fill="#263238"
          />
          <path
            d="M375.979 392.651H382.408V387.161H375.979V392.651Z"
            fill="#37474F"
          />
          <path
            d="M375.979 380.934H382.408V375.444H375.979V380.934Z"
            fill="#37474F"
          />
          <path
            d="M375.979 369.206H382.408V363.716H375.979V369.206Z"
            fill="#37474F"
          />
          <path
            d="M375.979 357.466H382.408V351.976H375.979V357.466Z"
            fill="#37474F"
          />
          <path
            d="M375.979 345.738H382.408V340.248H375.979V345.738Z"
            fill="#37474F"
          />
          <path
            d="M375.979 333.998H382.408V328.508H375.979V333.998Z"
            fill="#37474F"
          />
          <path
            d="M375.979 322.281H382.408V316.792H375.979V322.281Z"
            fill="#37474F"
          />
          <path
            d="M366.734 392.651H373.163V387.161H366.734V392.651Z"
            fill="#37474F"
          />
          <path
            d="M366.734 380.934H373.163V375.444H366.734V380.934Z"
            fill="#37474F"
          />
          <path
            d="M366.734 369.206H373.163V363.716H366.734V369.206Z"
            fill="#37474F"
          />
          <path
            d="M366.734 357.466H373.163V351.976H366.734V357.466Z"
            fill="#37474F"
          />
          <path
            d="M366.734 345.738H373.163V340.248H366.734V345.738Z"
            fill="#37474F"
          />
          <path
            d="M366.734 333.998H373.163V328.508H366.734V333.998Z"
            fill="#37474F"
          />
          <path
            d="M366.734 322.281H373.163V316.792H366.734V322.281Z"
            fill="#37474F"
          />
          <path
            d="M357.49 392.651H363.918V387.161H357.49V392.651Z"
            fill="#37474F"
          />
          <path
            d="M357.49 380.934H363.918V375.444H357.49V380.934Z"
            fill="#37474F"
          />
          <path
            d="M357.49 369.206H363.918V363.716H357.49V369.206Z"
            fill="#37474F"
          />
          <path
            d="M357.49 357.466H363.918V351.976H357.49V357.466Z"
            fill="#37474F"
          />
          <path
            d="M357.49 345.738H363.918V340.248H357.49V345.738Z"
            fill="#37474F"
          />
          <path
            d="M357.49 333.998H363.918V328.508H357.49V333.998Z"
            fill="#37474F"
          />
          <path
            d="M357.49 322.281H363.918V316.792H357.49V322.281Z"
            fill="#37474F"
          />
          <path
            d="M380.875 300.56H354.293V309.9H380.875V300.56Z"
            fill="#37474F"
          />
          <path
            d="M368.695 294.773H354.293V300.56H368.695V294.773Z"
            fill="#263238"
          />
          <path
            d="M342.137 53.0442H354.186C327.771 53.0442 306.371 74.7776 306.371 101.585C306.371 128.392 327.771 150.137 354.186 150.137H342.137C315.722 150.137 294.322 128.404 294.322 101.585C294.322 74.7657 315.722 53.0442 342.137 53.0442Z"
            fill="#C52031"
          />
          <g opacity="0.1">
            <path
              d="M342.137 53.0442H354.186C327.771 53.0442 306.371 74.7776 306.371 101.585C306.371 128.392 327.771 150.137 354.186 150.137H342.137C315.722 150.137 294.322 128.404 294.322 101.585C294.322 74.7657 315.722 53.0442 342.137 53.0442Z"
              fill="black"
            />
          </g>
          <path
            d="M354.186 287.905H342.137C390.678 231.343 436.141 169.364 429.463 100.919C425.126 56.5852 395.027 12.4293 342.137 12.4293H354.186C407.064 12.4293 437.163 56.5852 441.5 100.919C448.19 169.364 402.727 231.355 354.186 287.905Z"
            fill="#C52031"
          />
          <g opacity="0.1">
            <path
              d="M354.186 287.905H342.137C390.678 231.343 436.141 169.364 429.463 100.919C425.126 56.5852 395.027 12.4293 342.137 12.4293H354.186C407.064 12.4293 437.163 56.5852 441.5 100.919C448.19 169.364 402.727 231.355 354.186 287.905Z"
              fill="black"
            />
          </g>
          <path
            d="M429.463 100.919C436.153 169.364 390.69 231.355 342.149 287.917C293.668 231.355 248.205 169.364 254.883 100.919C259.232 56.5852 289.319 12.4411 342.149 12.4411C394.98 12.4411 425.126 56.5852 429.463 100.919ZM342.137 150.149C368.612 150.149 390.013 128.416 390.013 101.597C390.013 74.7776 368.612 53.0561 342.137 53.0561C315.663 53.0561 294.322 74.7895 294.322 101.597C294.322 128.404 315.722 150.149 342.137 150.149Z"
            fill="#C52031"
          />
          <path
            d="M2.26958 405.056L61.4214 404.771L120.573 404.664L238.877 404.474L357.181 404.664L416.333 404.771L475.484 405.056L416.333 405.353L357.181 405.46L238.877 405.65L120.573 405.46L61.4214 405.353L2.26958 405.056Z"
            fill="#263238"
          />
          <path
            d="M475.698 399.542H2.05569V405.056H475.698V399.542Z"
            fill="#C7C7C7"
          />
          <path
            d="M15.7564 399.542C15.9465 400.457 15.9822 401.384 15.994 402.299C15.994 403.214 15.9465 404.141 15.7564 405.056C15.5663 404.141 15.5306 403.214 15.5187 402.299C15.5187 401.384 15.5663 400.457 15.7564 399.542Z"
            fill="#263238"
          />
          <path
            d="M31.691 399.542C31.8812 400.457 31.9168 401.384 31.9287 402.299C31.9287 403.214 31.8812 404.141 31.691 405.056C31.5009 404.141 31.4653 403.214 31.4534 402.299C31.4534 401.384 31.5009 400.457 31.691 399.542Z"
            fill="#263238"
          />
          <path
            d="M47.6257 399.542C47.8158 400.457 47.8515 401.384 47.8633 402.299C47.8633 403.214 47.8158 404.141 47.6257 405.056C47.4356 404.141 47.3999 403.214 47.388 402.299C47.388 401.384 47.4356 400.457 47.6257 399.542Z"
            fill="#263238"
          />
          <path
            d="M63.5722 399.542C63.7623 400.457 63.798 401.384 63.8099 402.299C63.8099 403.214 63.7623 404.141 63.5722 405.056C63.3821 404.141 63.3464 403.214 63.3345 402.299C63.3345 401.384 63.3821 400.457 63.5722 399.542Z"
            fill="#263238"
          />
          <path
            d="M79.5069 399.542C79.697 400.457 79.7326 401.384 79.7445 402.299C79.7445 403.214 79.697 404.141 79.5069 405.056C79.3167 404.141 79.2811 403.214 79.2692 402.299C79.2692 401.384 79.3167 400.457 79.5069 399.542Z"
            fill="#263238"
          />
          <path
            d="M95.4415 399.542C95.6316 400.457 95.6673 401.384 95.6792 402.299C95.6792 403.214 95.6316 404.141 95.4415 405.056C95.2514 404.141 95.2157 403.214 95.2039 402.299C95.2039 401.384 95.2514 400.457 95.4415 399.542Z"
            fill="#263238"
          />
          <path
            d="M111.376 399.542C111.566 400.457 111.602 401.384 111.614 402.299C111.614 403.214 111.566 404.141 111.376 405.056C111.186 404.141 111.15 403.214 111.138 402.299C111.138 401.384 111.186 400.457 111.376 399.542Z"
            fill="#263238"
          />
          <path
            d="M127.323 399.542C127.513 400.457 127.548 401.384 127.56 402.299C127.56 403.214 127.513 404.141 127.323 405.056C127.133 404.141 127.097 403.214 127.085 402.299C127.085 401.384 127.133 400.457 127.323 399.542Z"
            fill="#263238"
          />
          <path
            d="M143.257 399.542C143.447 400.457 143.483 401.384 143.495 402.299C143.495 403.214 143.447 404.141 143.257 405.056C143.067 404.141 143.032 403.214 143.02 402.299C143.02 401.384 143.067 400.457 143.257 399.542Z"
            fill="#263238"
          />
          <path
            d="M159.192 399.542C159.382 400.457 159.418 401.384 159.43 402.299C159.43 403.214 159.382 404.141 159.192 405.056C159.002 404.141 158.966 403.214 158.954 402.299C158.954 401.384 159.002 400.457 159.192 399.542Z"
            fill="#263238"
          />
          <path
            d="M175.127 399.542C175.317 400.457 175.352 401.384 175.364 402.299C175.364 403.214 175.317 404.141 175.127 405.056C174.936 404.141 174.901 403.214 174.889 402.299C174.889 401.384 174.936 400.457 175.127 399.542Z"
            fill="#263238"
          />
          <path
            d="M191.073 399.542C191.263 400.457 191.299 401.384 191.311 402.299C191.311 403.214 191.263 404.141 191.073 405.056C190.883 404.141 190.847 403.214 190.835 402.299C190.835 401.384 190.883 400.457 191.073 399.542Z"
            fill="#263238"
          />
          <path
            d="M207.008 399.542C207.198 400.457 207.234 401.384 207.245 402.299C207.245 403.214 207.198 404.141 207.008 405.056C206.818 404.141 206.782 403.214 206.77 402.299C206.77 401.384 206.818 400.457 207.008 399.542Z"
            fill="#263238"
          />
          <path
            d="M222.942 399.542C223.133 400.457 223.168 401.384 223.18 402.299C223.18 403.214 223.133 404.141 222.942 405.056C222.752 404.141 222.717 403.214 222.705 402.299C222.705 401.384 222.752 400.457 222.942 399.542Z"
            fill="#263238"
          />
          <path
            d="M238.877 399.542C239.067 400.457 239.103 401.384 239.115 402.299C239.115 403.214 239.067 404.141 238.877 405.056C238.687 404.141 238.651 403.214 238.639 402.299C238.639 401.384 238.687 400.457 238.877 399.542Z"
            fill="#263238"
          />
          <path
            d="M254.824 399.542C255.014 400.457 255.049 401.384 255.061 402.299C255.061 403.214 255.014 404.141 254.824 405.056C254.633 404.141 254.598 403.214 254.586 402.299C254.586 401.384 254.633 400.457 254.824 399.542Z"
            fill="#263238"
          />
          <path
            d="M270.758 399.542C270.948 400.457 270.984 401.384 270.996 402.299C270.996 403.214 270.948 404.141 270.758 405.056C270.568 404.141 270.532 403.214 270.521 402.299C270.521 401.384 270.568 400.457 270.758 399.542Z"
            fill="#263238"
          />
          <path
            d="M286.693 399.542C286.883 400.457 286.919 401.384 286.931 402.299C286.931 403.214 286.883 404.141 286.693 405.056C286.503 404.141 286.467 403.214 286.455 402.299C286.455 401.384 286.503 400.457 286.693 399.542Z"
            fill="#263238"
          />
          <path
            d="M302.628 399.542C302.818 400.457 302.853 401.384 302.865 402.299C302.865 403.214 302.818 404.141 302.628 405.056C302.437 404.141 302.402 403.214 302.39 402.299C302.39 401.384 302.437 400.457 302.628 399.542Z"
            fill="#263238"
          />
          <path
            d="M318.574 399.542C318.764 400.457 318.8 401.384 318.812 402.299C318.812 403.214 318.764 404.141 318.574 405.056C318.384 404.141 318.348 403.214 318.336 402.299C318.336 401.384 318.384 400.457 318.574 399.542Z"
            fill="#263238"
          />
          <path
            d="M334.509 399.542C334.699 400.457 334.734 401.384 334.746 402.299C334.746 403.214 334.699 404.141 334.509 405.056C334.319 404.141 334.283 403.214 334.271 402.299C334.271 401.384 334.319 400.457 334.509 399.542Z"
            fill="#263238"
          />
          <path
            d="M350.443 399.542C350.633 400.457 350.669 401.384 350.681 402.299C350.681 403.214 350.633 404.141 350.443 405.056C350.253 404.141 350.218 403.214 350.206 402.299C350.206 401.384 350.253 400.457 350.443 399.542Z"
            fill="#263238"
          />
          <path
            d="M366.378 399.542C366.568 400.457 366.604 401.384 366.616 402.299C366.616 403.214 366.568 404.141 366.378 405.056C366.188 404.141 366.152 403.214 366.14 402.299C366.14 401.384 366.188 400.457 366.378 399.542Z"
            fill="#263238"
          />
          <path
            d="M382.324 399.542C382.515 400.457 382.55 401.384 382.562 402.299C382.562 403.214 382.515 404.141 382.324 405.056C382.134 404.141 382.099 403.214 382.087 402.299C382.087 401.384 382.134 400.457 382.324 399.542Z"
            fill="#263238"
          />
          <path
            d="M398.259 399.542C398.449 400.457 398.485 401.384 398.497 402.299C398.497 403.214 398.449 404.141 398.259 405.056C398.069 404.141 398.033 403.214 398.021 402.299C398.021 401.384 398.069 400.457 398.259 399.542Z"
            fill="#263238"
          />
          <path
            d="M414.194 399.542C414.384 400.457 414.42 401.384 414.431 402.299C414.431 403.214 414.384 404.141 414.194 405.056C414.004 404.141 413.968 403.214 413.956 402.299C413.956 401.384 414.004 400.457 414.194 399.542Z"
            fill="#263238"
          />
          <path
            d="M430.128 399.542C430.319 400.457 430.354 401.384 430.366 402.299C430.366 403.214 430.319 404.141 430.128 405.056C429.938 404.141 429.903 403.214 429.891 402.299C429.891 401.384 429.938 400.457 430.128 399.542Z"
            fill="#263238"
          />
          <path
            d="M446.075 399.542C446.265 400.457 446.301 401.384 446.313 402.299C446.313 403.214 446.265 404.141 446.075 405.056C445.885 404.141 445.849 403.214 445.837 402.299C445.837 401.384 445.885 400.457 446.075 399.542Z"
            fill="#263238"
          />
          <path
            d="M462.01 399.542C462.2 400.457 462.235 401.384 462.247 402.299C462.247 403.214 462.2 404.141 462.01 405.056C461.819 404.141 461.784 403.214 461.772 402.299C461.772 401.384 461.819 400.457 462.01 399.542Z"
            fill="#263238"
          />
          <path
            d="M51.6777 399.542H25.5596C25.5596 399.542 25.619 395.657 28.1857 394.623C30.7523 393.589 32.2852 395.609 32.2852 395.609C32.2852 395.609 33.3309 388.456 38.1196 388.456C42.9083 388.456 45.1898 394.623 45.1898 394.623C45.1898 394.623 46.2592 392.758 48.9209 393.102C51.5826 393.447 51.6777 399.542 51.6777 399.542Z"
            fill="#37474F"
          />
          <path
            d="M471.623 399.542C471.623 399.542 470.827 395.811 469.222 395.229C467.618 394.659 466.656 396.334 466.656 396.334C466.656 396.334 466.276 392.769 464.006 391.7C461.736 390.631 460.037 392.817 460.037 392.817C460.037 392.817 458.302 388.29 455.189 388.29C452.076 388.29 450.507 394.92 450.507 394.92C450.507 394.92 448.677 392.948 446.788 394.065C444.911 395.182 444.911 399.554 444.911 399.554H471.623V399.542Z"
            fill="#37474F"
          />
          <path
            d="M227.695 399.542C227.695 399.542 226.899 395.811 225.295 395.229C223.691 394.659 222.729 396.334 222.729 396.334C222.729 396.334 222.348 392.769 220.079 391.7C217.809 390.631 216.11 392.817 216.11 392.817C216.11 392.817 214.375 388.29 211.262 388.29C208.149 388.29 206.58 394.92 206.58 394.92C206.58 394.92 204.75 392.948 202.861 394.065C200.983 395.182 200.983 399.554 200.983 399.554H227.695V399.542Z"
            fill="#37474F"
          />
          <path
            d="M30.2295 399.542H6.14334C6.14334 399.542 6.93948 396.144 8.53175 395.906C10.124 395.669 10.8726 397.237 10.8726 397.237C10.8726 397.237 10.8013 392.496 13.7839 391.7C16.7664 390.892 19.2737 395.075 19.2737 395.075C19.2737 395.075 21.1393 392.686 23.694 392.841C26.2369 392.995 26.8905 396.94 26.8905 396.94C26.8905 396.94 29.2076 395.574 30.2414 399.542H30.2295Z"
            fill="#455A64"
          />
          <path
            d="M206.188 399.542H182.102C182.102 399.542 182.898 396.144 184.49 395.906C186.082 395.669 186.831 397.237 186.831 397.237C186.831 397.237 186.76 392.496 189.742 391.7C192.725 390.892 195.232 395.075 195.232 395.075C195.232 395.075 197.098 392.686 199.652 392.841C202.195 392.995 202.849 396.94 202.849 396.94C202.849 396.94 205.166 395.574 206.2 399.542H206.188Z"
            fill="#455A64"
          />
          <path
            d="M446.633 399.542H431.84C431.84 399.542 431.875 397.344 433.325 396.75C434.775 396.168 435.642 397.309 435.642 397.309C435.642 397.309 436.236 393.257 438.945 393.257C441.655 393.257 442.95 396.75 442.95 396.75C442.95 396.75 443.556 395.693 445.065 395.895C446.574 396.097 446.633 399.542 446.633 399.542Z"
            fill="#455A64"
          />
          <path
            d="M265.577 399.542H250.784C250.784 399.542 250.819 397.344 252.269 396.75C253.719 396.168 254.586 397.309 254.586 397.309C254.586 397.309 255.18 393.257 257.889 393.257C260.599 393.257 261.894 396.75 261.894 396.75C261.894 396.75 262.5 395.693 264.009 395.895C265.518 396.097 265.577 399.542 265.577 399.542Z"
            fill="#455A64"
          />
          <path
            d="M204.263 463.531C201.494 464.422 122.486 463.424 119.504 461.463C118.363 460.738 117.828 449.224 117.603 435.286C117.603 435.143 117.603 434.988 117.603 434.846C117.543 431.97 117.508 429.011 117.508 426.017C117.508 425.601 117.508 425.197 117.496 424.781L121.322 383.798L174.176 384.321L168.971 424.924L168.912 426.528L168.591 434.929L168.556 435.796C168.556 435.796 198.631 449.711 201.684 452.195C204.714 454.678 207.055 462.639 204.275 463.531H204.263Z"
            fill="#F3A78A"
          />
          <path
            d="M204.263 463.531C201.494 464.422 122.486 463.424 119.504 461.463C118.363 460.738 117.828 449.224 117.603 435.286C117.603 435.143 117.603 434.989 117.603 434.846C117.543 431.97 117.508 429.012 117.508 426.017C117.508 425.601 117.508 425.197 117.496 424.781L168.96 424.912L168.9 426.516L168.579 434.917L168.544 435.785C168.544 435.785 198.619 449.699 201.672 452.183C204.703 454.666 207.043 462.628 204.263 463.519V463.531Z"
            fill="#EBEBEB"
          />
          <path
            d="M204.263 463.519C202.837 463.982 181.377 463.935 160.392 463.519C156.174 463.436 151.979 463.341 147.975 463.234C133.014 462.83 120.645 462.212 119.504 461.463C118.363 460.75 117.84 449.545 117.615 435.868C117.615 435.678 117.603 435.476 117.603 435.286C117.603 435.143 117.603 434.988 117.591 434.834H117.65L168.567 434.917L168.544 435.785C168.544 435.785 198.619 449.687 201.661 452.183C204.691 454.666 207.032 462.639 204.263 463.519Z"
            fill="#C52031"
          />
          <path
            d="M203.407 460.453C190.099 459.954 136.342 459.205 123.152 459.728C123.045 459.728 123.045 459.811 123.152 459.811C136.342 460.596 190.087 460.905 203.407 460.667C203.692 460.667 203.692 460.465 203.407 460.453Z"
            fill="#263238"
          />
          <path
            d="M174.235 435.143C169.007 434.097 162.59 434.822 158.538 438.506C158.384 438.648 158.586 438.874 158.752 438.815C163.945 437.246 168.781 436.272 174.2 435.808C174.58 435.773 174.604 435.226 174.224 435.143H174.235Z"
            fill="#263238"
          />
          <path
            d="M177.99 437.472C172.762 436.426 166.345 437.151 162.293 440.835C162.139 440.977 162.341 441.203 162.507 441.144C167.7 439.575 172.536 438.601 177.955 438.137C178.335 438.102 178.359 437.555 177.978 437.472H177.99Z"
            fill="#263238"
          />
          <path
            d="M181.745 439.801C176.517 438.755 170.1 439.48 166.048 443.164C165.894 443.306 166.096 443.532 166.262 443.473C171.455 441.904 176.291 440.93 181.71 440.466C182.09 440.431 182.114 439.884 181.733 439.801H181.745Z"
            fill="#263238"
          />
          <path
            d="M160.392 463.519C156.174 463.436 151.979 463.341 147.975 463.234C133.014 462.83 120.645 462.212 119.504 461.463C118.363 460.75 117.84 449.545 117.615 435.868C117.615 435.678 117.603 435.476 117.603 435.286C117.603 435.143 117.603 434.988 117.591 434.834H117.65C124.625 439.73 149.211 456.092 160.392 463.519Z"
            fill="#263238"
          />
          <path
            d="M171.609 435.107H114.703C114.703 435.107 126.134 302.224 125.683 299.895C125.113 296.924 121.75 283.473 117.27 265.898C115.868 260.397 114.359 254.503 112.778 248.383C108.536 231.854 103.854 213.781 99.7787 198.06C93.6591 174.509 88.8823 156.209 88.8823 156.209H137.102C137.102 156.209 142.723 174.01 149.817 197.193C161.367 234.861 176.802 286.705 178.323 299.312C180.795 319.667 171.597 435.095 171.597 435.095L171.609 435.107Z"
            fill="#455A64"
          />
          <path
            d="M169.09 425.399C160.404 425.221 162.899 425.15 154.201 425.066C149.947 425.031 121.999 424.793 118.03 425.447C117.947 425.459 117.947 425.613 118.03 425.625C121.999 426.278 149.959 426.029 154.201 425.993C162.888 425.91 160.392 425.839 169.09 425.649C169.257 425.649 169.257 425.399 169.09 425.399Z"
            fill="#263238"
          />
          <path
            d="M136.401 291.767C134.535 283.544 131.743 275.476 129.319 267.407C124.388 251.009 119.409 234.611 114.418 218.225C111.923 210.026 109.427 201.839 106.908 193.652C105.637 189.517 104.365 185.381 103.07 181.258C101.799 177.218 100.361 173.214 99.2202 169.138C98.7568 167.486 98.4954 168.389 99.1014 170.492C100.302 174.734 101.49 178.988 102.726 183.219C105.173 191.608 107.645 199.985 110.128 208.351C115.06 225.022 120.062 241.67 125.065 258.317C127.572 266.671 130.091 275.012 132.599 283.354C133.799 287.346 134.975 291.351 135.676 295.462C136.413 299.752 136.449 304.089 136.187 308.426C135.641 317.136 134.642 325.822 133.834 334.509C133.026 343.207 132.23 351.917 131.422 360.615C129.818 377.94 128.226 395.253 126.669 412.578C126.467 414.776 126.277 416.974 126.111 419.173C126.051 419.909 127.168 419.85 127.24 419.149C129.105 402.026 130.543 384.832 132.147 367.685C133.739 350.61 135.343 333.546 136.936 316.471C137.34 312.181 137.791 307.892 137.791 303.578C137.791 299.586 137.292 295.641 136.401 291.743V291.767Z"
            fill="#263238"
          />
          <path
            d="M131.636 156.198C128.297 182.102 121.928 230.666 117.282 265.898C106.397 223.216 88.8942 156.209 88.8942 156.209H131.648L131.636 156.198Z"
            fill="#263238"
          />
          <path
            d="M142.948 464.303C140.18 465.206 61.1719 464.493 58.1775 462.544C57.0249 461.831 56.4664 450.305 56.1812 436.367C56.1812 436.224 56.1812 436.07 56.1812 435.927C56.1218 433.052 56.0624 430.093 56.0505 427.098C56.0505 426.682 56.0505 426.279 56.0267 425.863C55.8366 405.745 57.2863 384.879 57.2863 384.879L108.928 385.2L107.491 425.803L107.431 427.407L107.146 435.808L107.122 436.676C107.122 436.676 137.245 450.483 140.31 452.955C143.352 455.427 145.717 463.388 142.948 464.279V464.303Z"
            fill="#F3A78A"
          />
          <path
            d="M142.948 464.303C140.18 465.206 61.1719 464.493 58.1775 462.544C57.0249 461.831 56.4664 450.305 56.1812 436.367C56.1812 436.224 56.1812 436.07 56.1812 435.927C56.1218 433.052 56.0624 430.093 56.0505 427.098C56.0505 426.683 56.0505 426.279 56.0267 425.863L107.491 425.803L107.431 427.407L107.146 435.808L107.122 436.676C107.122 436.676 137.245 450.483 140.31 452.955C143.352 455.427 145.717 463.388 142.948 464.279V464.303Z"
            fill="#EBEBEB"
          />
          <path
            d="M142.948 464.303C140.18 465.206 61.1719 464.493 58.1775 462.544C57.0249 461.831 56.4664 450.305 56.1812 436.367C56.1812 436.224 56.1812 436.07 56.1812 435.927L107.158 435.82L107.134 436.688C107.134 436.688 137.257 450.495 140.322 452.967C143.364 455.439 145.729 463.4 142.96 464.291L142.948 464.303Z"
            fill="#C52031"
          />
          <path
            d="M142.081 461.225C128.76 460.774 75.0152 460.227 61.8136 460.798C61.7066 460.798 61.7066 460.881 61.8136 460.881C75.0033 461.618 128.749 461.724 142.069 461.451C142.354 461.451 142.354 461.237 142.069 461.237L142.081 461.225Z"
            fill="#263238"
          />
          <path
            d="M112.826 436.034C107.597 435.012 101.181 435.749 97.1407 439.456C96.9862 439.599 97.1882 439.825 97.3546 439.765C102.535 438.185 107.384 437.175 112.802 436.7C113.182 436.664 113.206 436.105 112.826 436.034Z"
            fill="#263238"
          />
          <path
            d="M116.593 438.339C111.364 437.317 104.948 438.054 100.908 441.762C100.753 441.904 100.955 442.13 101.121 442.071C106.302 440.49 111.15 439.48 116.569 439.005C116.949 438.969 116.973 438.411 116.593 438.339Z"
            fill="#263238"
          />
          <path
            d="M120.359 440.656C115.131 439.635 108.714 440.371 104.674 444.079C104.52 444.221 104.722 444.447 104.888 444.388C110.069 442.807 114.917 441.797 120.324 441.322C120.704 441.286 120.728 440.728 120.348 440.656H120.359Z"
            fill="#263238"
          />
          <path
            d="M135.557 156.198C135.557 156.198 134.452 179.274 129.176 197.549C128.749 199.058 128.297 200.544 127.798 201.97C127.798 201.97 122.296 203.966 113.895 209.111C113.895 209.111 113.349 227.672 112.79 248.383C112.184 271.531 111.543 297.34 111.673 302.818C112.16 323.303 109.142 435.951 109.142 435.951L52.248 436.189C52.248 436.189 62.5028 310.399 62.4315 302.889C62.3245 290.828 65.1764 237.511 67.3747 198.631C68.7174 174.675 69.8344 156.209 69.8344 156.209H135.545L135.557 156.198Z"
            fill="#455A64"
          />
          <path
            d="M107.193 425.827C98.5072 425.684 101.003 425.601 92.3045 425.554C88.0505 425.53 60.1025 425.411 56.1337 426.088C56.0505 426.1 56.0505 426.255 56.1337 426.267C60.1144 426.908 88.0624 426.54 92.3164 426.481C101.003 426.362 98.5072 426.302 107.205 426.077C107.372 426.077 107.372 425.827 107.205 425.827H107.193Z"
            fill="#263238"
          />
          <path
            d="M74.6231 164.456C73.5893 196.195 72.4842 227.933 71.0702 259.66C69.6561 291.493 67.743 323.315 65.0813 355.078C63.596 372.818 62.2057 397.772 60.2926 415.477C60.2807 415.596 60.459 415.643 60.4827 415.513C64.6179 384.024 66.9231 345.096 68.9432 313.417C70.9751 281.738 72.3416 249.999 73.4229 218.284C74.029 200.342 74.4924 182.387 75.0271 164.444C75.0271 164.195 74.635 164.206 74.635 164.468L74.6231 164.456Z"
            fill="#263238"
          />
          <path
            d="M130.163 200.591C124.495 202.837 119.076 205.915 113.895 209.111C113.693 209.242 113.848 209.551 114.062 209.444C119.551 206.782 125.148 204.203 130.483 201.245C130.899 201.007 130.59 200.413 130.151 200.591H130.163Z"
            fill="#263238"
          />
          <path
            d="M130.472 181.02C130.091 183.04 129.604 185.049 129.164 187.057C128.76 188.946 128.452 190.871 127.952 192.737C127.465 194.543 126.705 196.206 125.315 197.49C124.578 198.179 123.758 198.785 122.974 199.415C122.546 199.759 122.094 200.08 121.643 200.425C121.702 200.163 121.75 199.89 121.797 199.617C121.928 198.856 122.059 198.084 122.189 197.312C122.475 195.612 122.748 193.901 123.009 192.19C123.52 188.839 123.996 185.441 124.697 182.126C124.72 181.995 124.518 181.935 124.483 182.078C123.651 185.369 122.724 188.625 121.999 191.941C121.643 193.569 120.371 201.613 120.312 202.172C120.253 202.754 120.205 203.348 120.181 203.942C120.157 204.524 120.05 205.178 120.181 205.748C120.217 205.891 120.395 205.867 120.455 205.772C120.752 205.261 120.823 204.596 120.965 204.025C121.108 203.455 121.239 202.873 121.346 202.29C121.381 202.076 121.417 201.863 121.441 201.649C122.237 201.162 122.974 200.532 123.71 199.997C124.506 199.415 125.326 198.856 126.075 198.227C127.525 197.014 128.428 195.517 128.962 193.711C129.521 191.846 129.687 189.861 129.913 187.936C130.163 185.655 130.353 183.361 130.674 181.08C130.697 180.949 130.495 180.89 130.46 181.032L130.472 181.02Z"
            fill="#263238"
          />
          <path
            d="M115.072 96.6892C118.221 105.482 129.343 125.208 146.382 140.75C152.965 146.739 202.029 142.806 206.889 140.916C210.287 139.586 216.633 114.858 213.056 112.624C206.354 108.441 162.495 118.102 160.63 117.115C157.778 115.594 138.896 102.939 126.729 90.9142C117.852 82.1448 109.831 82.0616 115.072 96.7011V96.6892Z"
            fill="#F3A78A"
          />
          <path
            d="M127.679 122.677L155.52 111.614C155.52 111.614 130.543 90.1418 121.417 85.8997C111.138 81.1229 110.948 87.4207 114.561 97.5685C118.173 107.716 127.691 122.677 127.691 122.677H127.679Z"
            fill="#37474F"
          />
          <path
            d="M149.638 108.239C148.616 108.477 139.883 111.78 138.005 112.541C136.128 113.301 128.404 116.331 126.527 117.282C126.443 117.329 126.479 117.448 126.574 117.424C128.582 116.854 136.377 113.812 138.314 113.075C140.251 112.339 148.807 108.869 149.698 108.358C149.769 108.31 149.698 108.215 149.638 108.227V108.239Z"
            fill="#263238"
          />
          <path
            d="M138.409 118.411L127.679 122.677C127.679 122.677 118.161 107.716 114.549 97.5804C111.911 90.1656 111.305 84.7946 115.179 84.3193L138.409 118.411Z"
            fill="#263238"
          />
          <path
            d="M63.489 181.317C63.6673 181.519 142.378 182.03 143.091 181.234C144.101 180.105 126.622 94.7642 118.577 84.2005C116.854 81.9428 106.005 78.8652 99.7074 83.9985C86.3275 94.8949 74.7182 132.302 72.0208 143.59C67.5648 162.186 62.8355 180.581 63.4772 181.329L63.489 181.317Z"
            fill="#C52031"
          />
          <path
            opacity="0.1"
            d="M119.029 84.8659H98.7092C99.0419 84.557 99.3747 84.2718 99.7074 83.9985C99.9094 83.8322 100.123 83.6777 100.337 83.5351H117.84C118.161 83.7609 118.411 83.9866 118.577 84.2005C118.732 84.3906 118.874 84.6164 119.029 84.8659Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M120.93 88.6922H95.2039C95.5722 88.2287 95.9406 87.7772 96.3208 87.3494H120.348C120.538 87.7653 120.728 88.2169 120.93 88.6922Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M122.403 92.5184H92.4471C92.756 92.055 93.0531 91.6153 93.362 91.1756H121.916C122.071 91.6034 122.237 92.055 122.403 92.5184Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M123.698 96.3327H90.1062C90.3676 95.8812 90.629 95.4296 90.9023 94.99H123.259C123.413 95.4296 123.556 95.8812 123.698 96.3327Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M124.91 100.159H88.0148C88.2525 99.7074 88.4783 99.2558 88.7278 98.8162H124.495C124.625 99.2558 124.768 99.7074 124.91 100.159Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M126.039 103.985H86.1017C86.3156 103.534 86.5295 103.082 86.7553 102.642H125.647C125.778 103.082 125.909 103.534 126.039 103.985Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M127.121 107.799H84.3431C84.5451 107.348 84.7471 106.908 84.9491 106.457H126.74C126.871 106.896 126.99 107.348 127.121 107.799Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M128.154 111.626H82.6914C82.8815 111.174 83.0717 110.723 83.2618 110.283H127.798C127.917 110.723 128.036 111.174 128.154 111.626Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M129.164 115.452H81.1467C81.313 115 81.4913 114.561 81.6814 114.109H128.82C128.927 114.549 129.046 115 129.164 115.452Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M130.139 119.266H79.6732C79.8396 118.815 80.0059 118.375 80.1842 117.924H129.806C129.913 118.375 130.032 118.815 130.139 119.266Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M131.101 123.092H78.2829C78.4374 122.641 78.6038 122.201 78.7701 121.75H130.769C130.876 122.201 130.983 122.641 131.101 123.092Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M132.028 126.907H76.964C77.1066 126.467 77.261 126.016 77.4274 125.576H131.707C131.814 126.016 131.921 126.467 132.028 126.907Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M132.943 130.733H75.7044C75.847 130.293 75.9896 129.842 76.1322 129.39H132.622C132.729 129.842 132.836 130.281 132.943 130.733Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M133.834 134.559H74.5161C74.6469 134.12 74.7776 133.668 74.9202 133.216H133.537C133.632 133.668 133.739 134.12 133.834 134.559Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M134.726 138.374H73.3992C73.518 137.946 73.6487 137.506 73.7794 137.043H134.417C134.512 137.494 134.619 137.934 134.726 138.374Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M135.581 142.2H72.3654C72.4723 141.784 72.5912 141.332 72.71 140.857H135.284C135.379 141.309 135.486 141.748 135.581 142.2Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M136.437 146.026H71.4385C71.5455 145.586 71.6524 145.135 71.7594 144.683H136.14C136.235 145.135 136.33 145.586 136.437 146.026Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M137.268 149.84H70.5117C70.6186 149.401 70.7256 148.961 70.8325 148.509H136.983C137.078 148.949 137.173 149.401 137.268 149.84Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M138.076 153.667H69.5848C69.6918 153.215 69.7987 152.775 69.9057 152.324H137.791C137.898 152.775 137.993 153.215 138.076 153.667Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M138.884 157.493H68.658C68.7649 157.053 68.8719 156.602 68.9788 156.15H138.611C138.706 156.602 138.801 157.053 138.884 157.493Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M139.669 161.307H67.7311C67.8381 160.867 67.945 160.416 68.052 159.964H139.395C139.491 160.416 139.586 160.867 139.669 161.307Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M140.441 165.133H66.7924C66.9112 164.694 67.0182 164.242 67.1251 163.791H140.168C140.263 164.242 140.358 164.694 140.441 165.133Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M141.19 168.96H65.8893C65.9963 168.52 66.0913 168.08 66.2102 167.617H140.928C141.011 168.08 141.107 168.52 141.19 168.96Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M141.903 172.774H64.9981C65.0932 172.334 65.2001 171.895 65.3071 171.431H141.653C141.736 171.895 141.82 172.334 141.903 172.774Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M142.568 176.6H64.1545C64.2376 176.184 64.3327 175.733 64.4515 175.257H142.342C142.426 175.733 142.497 176.172 142.568 176.6Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M143.115 180.426H63.4653C63.5009 180.07 63.5722 179.618 63.6673 179.084H142.948C143.032 179.63 143.079 180.082 143.115 180.426Z"
            fill="black"
          />
          <path
            d="M122.391 183.563L134.987 129.759L139.942 81.5269L166.036 88.4545L187.853 84.0817L207.352 92.4233L235.122 86.1374L216.348 137.078L208.434 188.661L187.425 193.866L166.31 184.799L144.909 191.798L122.391 183.563Z"
            fill="#EBEBEB"
          />
          <path
            d="M144.909 191.798L166.036 88.4545L187.853 84.0817L166.31 184.799L144.909 191.798Z"
            fill="#DBDBDB"
          />
          <path
            d="M187.425 193.866L207.352 92.4233L235.122 86.1374L216.348 137.078L208.434 188.661L187.425 193.866Z"
            fill="#DBDBDB"
          />
          <path
            d="M229.894 109.19C230.761 114.299 234.564 121.429 233.97 125.231C233.435 128.665 230.333 129.058 230.333 129.058C230.333 129.058 233.494 131.933 232.377 135.296C231.807 137.031 228.539 137.637 228.539 137.637C228.539 137.637 230.963 140.049 229.799 142.984C228.86 145.325 225.723 145.574 225.723 145.574C225.723 145.574 226.971 148.973 225.26 151.052C221.778 155.259 200.995 149.377 199.95 140.821C199.462 136.829 204.465 138.54 204.465 138.54C204.465 138.54 197.87 134.345 198.975 130.032C200.08 125.719 206.437 128.333 206.437 128.333C206.437 128.333 200.08 124.209 201.672 119.848C203.336 115.309 209.242 119.017 209.242 119.017C209.242 119.017 205.511 114.573 207.495 111.519C210.775 106.469 219.294 116.367 224.178 116.581L226.662 109.273L229.918 109.214L229.894 109.19Z"
            fill="#F3A78A"
          />
          <path
            d="M231.771 116.771C231.379 111.863 235.122 110.853 236.061 106.183C237.107 101.003 230.726 100.622 226.721 109L224.143 116.569L231.759 116.783L231.771 116.771Z"
            fill="#F3A78A"
          />
          <path
            d="M225.224 145.681C225.295 145.693 225.283 145.8 225.224 145.8C217.916 145.539 209.967 142.996 204.168 138.469C203.978 138.326 204.132 137.946 204.382 138.065C211.25 141.594 217.631 144.172 225.236 145.681H225.224Z"
            fill="#263238"
          />
          <path
            d="M228.622 137.708C228.706 137.72 228.682 137.863 228.587 137.863C221.053 137.542 212.189 134.452 206.117 128.44C205.903 128.238 205.926 127.846 206.188 128.059C210.525 131.755 221.766 136.389 228.622 137.72V137.708Z"
            fill="#263238"
          />
          <path
            d="M208.933 118.423C214.969 123.96 222.182 127.002 230.036 129.069C230.143 129.093 230.096 129.236 230.001 129.236C225.889 129.271 221.017 127.442 217.346 125.695C213.828 124.019 211 121.797 208.743 118.636C208.659 118.518 208.802 118.304 208.933 118.423Z"
            fill="#263238"
          />
          <path
            d="M89.1675 91.0212C75.3123 106.385 48.4575 146.014 53.6264 156.53C60.5778 170.694 86.9573 191.834 96.404 195.28C100.48 196.765 118.007 167.237 114.24 164.527C109.724 161.271 84.6283 143.424 84.8778 141.748C85.1155 140.156 93.0174 120.763 97.319 103.747C102.322 83.9985 96.7724 82.5964 89.1675 91.033V91.0212Z"
            fill="#F3A78A"
          />
          <path
            d="M97.117 161.735C97.117 161.735 109.855 158.301 118.292 156.447C122.997 155.413 131.232 154.356 135.035 155.176C140.726 156.411 163.755 165.181 161.747 170.813C159.929 175.911 141.107 165.858 141.107 165.858C141.107 165.858 160.998 174.319 157.683 180.058C154.368 185.785 134.607 171.597 134.607 171.597C134.607 171.597 154.855 180.759 150.494 186.13C147.202 190.194 128.582 178.347 128.582 178.347C128.582 178.347 145.931 186.332 142.723 190.657C139.182 195.434 121.31 185.583 121.31 185.583C111.317 195.196 102.345 197.003 96.404 195.292C94.9543 194.876 97.117 161.747 97.117 161.747V161.735Z"
            fill="#F3A78A"
          />
          <path
            d="M134.417 162.483C136.793 163.398 139.193 164.302 141.475 165.418C143.756 166.535 146.002 167.736 148.177 169.055C153.5 172.31 156.316 175.174 156.126 175.02C151.551 171.277 143.436 167.153 141.142 166.036C138.861 164.919 136.639 163.696 134.357 162.579C134.298 162.555 134.357 162.472 134.405 162.495L134.417 162.483Z"
            fill="#263238"
          />
          <path
            d="M130.234 170.659C133.216 171.954 136.223 173.13 139.146 174.556C142.057 175.97 144.695 177.574 147.499 179.19C147.713 179.309 147.583 179.392 147.357 179.274C144.493 177.776 141.724 176.6 138.849 175.138C135.973 173.677 133.133 172.097 130.21 170.73C130.163 170.706 130.21 170.647 130.246 170.659H130.234Z"
            fill="#263238"
          />
          <path
            d="M125.374 177.028C130.032 178.775 134.357 181.365 138.54 184.027C138.754 184.157 138.564 184.241 138.338 184.11C134.001 181.698 130.626 179.785 125.314 177.194C125.231 177.159 125.279 176.992 125.374 177.028Z"
            fill="#263238"
          />
          <path
            d="M91.746 124.506L62.3721 118.66C62.3721 118.66 83.0598 93.0293 91.2707 87.2068C100.515 80.6595 101.846 86.8147 100.123 97.4497C98.4003 108.085 91.746 124.518 91.746 124.518V124.506Z"
            fill="#37474F"
          />
          <path
            d="M92.0074 119.373C91.0212 119.017 81.8834 117.127 79.8871 116.759C77.8908 116.391 69.7512 114.798 67.6599 114.596C67.5648 114.596 67.5291 114.715 67.6242 114.739C69.6324 115.333 77.8433 116.961 79.8633 117.377C81.8834 117.793 90.9736 119.468 91.9955 119.516C92.0787 119.516 92.0787 119.397 92.0074 119.373Z"
            fill="#263238"
          />
          <path
            d="M109.023 78.2829C102.547 77.5581 94.8117 83.0954 100.539 90.6053C106.267 98.1151 112.481 99.5529 115.844 98.5072C119.896 97.2477 121.417 89.0367 120.407 85.1749C119.397 81.313 114.549 78.9008 109.023 78.2829Z"
            fill="#455A64"
          />
          <path
            d="M114.347 95.8099C111.638 96.2139 103.688 91.2588 100.67 85.9116C100.468 85.5433 101.822 70.7493 101.799 67.7906C101.799 67.1845 120.407 72.0802 120.407 72.0802C120.407 72.0802 118.66 78.3067 118.743 83.4638C118.755 83.8916 118.803 84.2956 118.886 84.6758C118.886 84.7352 118.91 84.7946 118.91 84.8778C119.314 87.1831 118.316 95.2157 114.347 95.8099Z"
            fill="#F3A78A"
          />
          <path
            d="M102.999 67.8737C103.747 72.3535 106.754 84.8897 117.02 85.0085C117.71 85.0085 118.339 84.9729 118.922 84.8778C118.922 84.8065 118.898 84.7352 118.886 84.6758C118.815 84.2956 118.767 83.8916 118.755 83.4638C118.66 78.3067 120.407 72.0802 120.407 72.0802C120.407 72.0802 107.122 68.5867 102.999 67.8737Z"
            fill="#263238"
          />
          <path
            d="M129.01 39.1177C129.01 39.1177 134.619 42.8489 134.512 49.2774C134.405 55.694 131.719 61.9205 131.113 62.1344C130.519 62.3483 128.998 39.1177 128.998 39.1177H129.01Z"
            fill="#263238"
          />
          <path
            d="M103.605 42.7776C100.278 48.8021 99.743 68.1114 103.45 73.2328C108.821 80.6476 119.563 83.5232 126.36 76.6194C132.943 69.9294 133.205 44.7501 128.879 39.902C122.522 32.7605 108.524 33.8893 103.617 42.7776H103.605Z"
            fill="#F3A78A"
          />
          <path
            d="M119.373 58.1537C119.373 58.1537 119.266 58.2131 119.266 58.2607C119.266 59.5678 119.04 61.0531 117.852 61.4452C117.817 61.4571 117.817 61.5284 117.852 61.5165C119.29 61.362 119.563 59.3776 119.361 58.1418L119.373 58.1537Z"
            fill="#263238"
          />
          <path
            d="M118.256 56.7635C116.165 56.5377 115.868 60.7323 117.805 60.9343C119.706 61.1363 120.003 56.9536 118.256 56.7635Z"
            fill="#263238"
          />
          <path
            d="M126.527 58.5815C126.527 58.5815 126.622 58.6528 126.622 58.7003C126.491 59.9955 126.55 61.5046 127.691 62.0156C127.727 62.0275 127.715 62.0988 127.679 62.0869C126.265 61.7779 126.194 59.7817 126.527 58.5815Z"
            fill="#263238"
          />
          <path
            d="M127.786 57.3101C129.889 57.3101 129.747 61.5046 127.798 61.5046C125.885 61.5046 126.016 57.3101 127.786 57.3101Z"
            fill="#263238"
          />
          <path
            d="M116.474 55.3613C117.056 55.2544 117.579 55.0642 118.137 54.9098C118.779 54.7315 119.302 54.6483 119.742 54.1255C119.979 53.8403 119.932 53.3056 119.67 53.0442C119.076 52.4382 118.244 52.4738 117.472 52.6639C116.628 52.8659 116.034 53.2343 115.511 53.9235C115 54.5889 115.701 55.5039 116.462 55.3732L116.474 55.3613Z"
            fill="#263238"
          />
          <path
            d="M129.569 54.3869C128.998 54.2562 128.487 54.0304 127.929 53.8403C127.299 53.6264 126.788 53.5076 126.372 52.961C126.146 52.6639 126.229 52.1292 126.503 51.8797C127.121 51.3093 127.952 51.3925 128.725 51.6183C129.557 51.8678 130.127 52.2718 130.614 52.9967C131.078 53.6858 130.341 54.5652 129.58 54.3869H129.569Z"
            fill="#263238"
          />
          <path
            d="M116.961 69.7869C117.246 70.1077 117.531 70.5592 117.995 70.6305C118.446 70.7018 118.993 70.5355 119.409 70.4047C119.456 70.3929 119.492 70.4404 119.456 70.476C119.088 70.9514 118.351 71.2128 117.757 71.0583C117.175 70.9157 116.925 70.381 116.818 69.8344C116.807 69.7631 116.902 69.7393 116.949 69.7869H116.961Z"
            fill="#263238"
          />
          <path
            d="M118.613 65.9963C119.254 67.1727 120.288 67.8619 121.417 68.1708C121.928 68.3134 122.463 68.3728 122.997 68.3609C123.104 68.3609 123.211 68.3609 123.306 68.349C123.401 68.349 123.485 68.349 123.592 68.3253C123.746 68.3253 123.841 68.1946 123.877 68.052C123.877 68.052 123.877 67.9569 123.877 67.9094V67.7906C123.972 66.7924 123.984 65.2714 123.984 65.2714C124.34 65.4853 126.134 66.4478 126.146 65.925C126.194 61.5878 126.253 56.7516 125.386 52.4263C125.362 52.2837 125.148 52.2956 125.148 52.4382C124.934 56.609 125.457 60.7917 125.303 64.9863C124.72 64.713 123.782 64.2258 123.259 64.202C123.116 64.2376 123.283 67.1489 123.211 67.6004C123.211 67.6004 123.211 67.6242 123.211 67.648C121.512 67.743 120.288 67.1489 118.743 65.9012C118.648 65.818 118.541 65.9012 118.613 66.0082V65.9963Z"
            fill="#263238"
          />
          <path
            d="M122.451 67.8143C122.451 67.8143 121.476 68.9432 120.229 69.2878C119.765 69.4185 119.29 69.4423 118.791 69.2759C117.769 68.9313 117.817 67.9807 118.066 67.1727C118.268 66.5191 118.625 65.9606 118.625 65.9606C118.625 65.9606 120.039 67.4816 122.451 67.8143Z"
            fill="#263238"
          />
          <path
            d="M120.241 69.2878C119.777 69.4185 119.302 69.4423 118.803 69.2759C117.781 68.9313 117.828 67.9807 118.078 67.1727C119.183 67.3628 120.229 68.1946 120.241 69.2878Z"
            fill="#FF9CBD"
          />
          <path
            d="M101.312 60.958C103.617 61.7185 106.445 54.8028 107.265 52.1292C107.978 49.7765 108.916 42.2904 109.047 41.7794C109.166 41.2685 119.017 48.6476 124.911 47.0672C130.804 45.4868 134.833 40.3179 134.963 37.87C135.094 35.4222 127.976 29.0293 121.69 28.5778C115.404 28.1144 106.718 35.9213 106.718 35.9213C106.718 35.9213 109.689 32.5347 109.594 32.2495C109.499 31.9643 105.399 33.4972 104.591 36.3728C104.591 36.3728 105.482 32.7605 105.173 32.6892C104.864 32.6179 101.644 35.6599 102.298 38.0245C102.298 38.0245 98.5785 41.1021 98.1626 44.56C97.7467 48.0178 98.6023 60.055 101.312 60.9462V60.958Z"
            fill="#263238"
          />
          <path
            d="M129.081 45.9502C127.287 46.7345 125.481 47.59 123.508 47.8039C121.381 48.0416 119.183 47.4593 117.222 46.6513C115.214 45.8314 113.408 44.6669 111.768 43.2529C110.33 42.0171 109.071 40.5317 107.526 39.4267C107.146 39.1534 106.671 39.7 106.908 40.0683C109.202 43.6807 113.337 46.7464 117.353 48.1248C121.583 49.5744 125.6 48.707 129.105 45.9978C129.129 45.9859 129.105 45.9384 129.081 45.9502Z"
            fill="#263238"
          />
          <path
            d="M115.82 65.0694C113.289 64.6535 111.245 62.5741 111.008 59.9005C110.734 56.7397 113.075 53.9473 116.224 53.6621C119.385 53.3769 122.177 55.7178 122.463 58.8786C122.736 62.0394 120.395 64.8318 117.246 65.117C116.759 65.1645 116.284 65.1407 115.82 65.0694ZM117.543 54.4701C117.139 54.4107 116.723 54.3869 116.307 54.4226C113.574 54.6602 111.542 57.0843 111.78 59.8292C112.018 62.5622 114.442 64.5941 117.187 64.3565C119.92 64.1188 121.952 61.6948 121.714 58.9499C121.512 56.6327 119.742 54.8266 117.555 54.4701H117.543Z"
            fill="#C52031"
          />
          <path
            d="M129.01 65.5804C126.835 65.2239 125.231 62.681 125.362 59.6866C125.433 58.1775 125.921 56.7635 126.752 55.7178C127.608 54.6365 128.725 54.078 129.889 54.1255C131.066 54.173 132.111 54.8385 132.872 55.9911C133.609 57.1081 133.977 58.5459 133.906 60.055C133.763 63.2276 131.731 65.7111 129.378 65.616C129.248 65.616 129.129 65.5923 128.998 65.5804H129.01ZM130.151 54.9216C130.056 54.9098 129.961 54.8979 129.866 54.886C128.951 54.8503 128.059 55.3138 127.358 56.1931C126.633 57.1081 126.194 58.3557 126.134 59.7104C126.016 62.4552 127.489 64.7605 129.426 64.8437C131.363 64.9268 133.026 62.7642 133.157 60.0193C133.216 58.6647 132.896 57.3814 132.242 56.407C131.684 55.5633 130.959 55.0524 130.151 54.9216Z"
            fill="#C52031"
          />
          <path
            d="M122.249 59.544L121.785 58.938C122.688 58.2488 124.744 57.3457 126.087 58.9142L125.505 59.4133C124.304 58.0111 122.344 59.4846 122.261 59.544H122.249Z"
            fill="#C52031"
          />
          <path
            d="M97.7111 60.15L97.0457 59.7698C97.2239 59.4608 98.8399 56.7278 100.563 56.5258C102.179 56.3357 111.305 57.5002 111.745 57.5596L111.638 58.3201C108.703 57.9161 101.906 57.1437 100.658 57.2863C99.5172 57.417 98.1507 59.4014 97.7111 60.15Z"
            fill="#C52031"
          />
          <path
            d="M104.377 58.7835C104.377 58.7835 101.312 51.9391 98.2814 52.9254C95.2514 53.9116 96.7605 63.2633 99.743 65.0813C102.726 66.8875 104.46 64.1545 104.46 64.1545L104.377 58.7835Z"
            fill="#F3A78A"
          />
          <path
            d="M98.7686 55.5871C98.7686 55.5871 98.6973 55.6465 98.733 55.6703C100.812 57.013 101.609 59.3301 102.024 61.6591C101.514 60.6966 100.682 60.0668 99.4222 60.6491C99.3509 60.6847 99.3984 60.7798 99.4578 60.7798C100.42 60.756 101.05 61.0412 101.537 61.9086C101.882 62.5147 102.06 63.2395 102.25 63.9049C102.322 64.1307 102.702 64.0832 102.666 63.8455C102.666 63.8218 102.666 63.8099 102.666 63.7861C103.272 60.9224 101.799 56.4664 98.7686 55.599V55.5871Z"
            fill="#263238"
          />
          <path
            d="M206.711 42.3498C205.166 57.1081 191.941 67.8262 177.182 66.2815C171.217 65.6517 165.918 63.1207 161.806 59.342L148.355 61.3145L155.081 49.6933C153.453 45.7245 152.763 41.3041 153.239 36.7531C154.784 21.9948 168.009 11.2766 182.767 12.8214C197.525 14.3661 208.244 27.5915 206.699 42.3498H206.711Z"
            fill="white"
          />
          <path
            d="M206.711 42.3498C206.152 47.2573 204.358 52.0698 201.292 55.9792C200.591 57.0011 199.676 57.8567 198.856 58.7835C197.918 59.5915 197.05 60.4946 196.016 61.1838C194.056 62.6929 191.846 63.8812 189.528 64.7961C187.235 65.7586 184.763 66.2221 182.292 66.5191C181.044 66.5904 179.808 66.6974 178.561 66.5904C177.313 66.5667 176.065 66.4241 174.841 66.1983C169.922 65.3071 165.264 63.0019 161.545 59.6391L161.866 59.7341L148.438 61.8017L147.464 61.9562L147.963 61.1006L154.653 49.4556L154.617 49.919C151.361 42.2429 152.098 33.117 156.269 25.9636C158.348 22.3869 161.26 19.2974 164.682 17.0041C168.104 14.6988 172.085 13.2492 176.149 12.7026C180.224 12.1678 184.407 12.5362 188.304 13.8195C192.19 15.1266 195.755 17.3249 198.666 20.1768C204.596 25.821 207.578 34.2815 206.711 42.3736V42.3498ZM206.711 42.3498C207.531 34.2458 204.489 25.8448 198.547 20.2718C195.6 17.4913 192.036 15.3762 188.186 14.1522C184.324 12.9402 180.2 12.655 176.232 13.261C172.263 13.8671 168.413 15.3524 165.133 17.6457C161.854 19.951 159.085 22.9573 157.089 26.4152C155.057 29.8611 153.999 33.818 153.678 37.7869C153.429 41.7675 154.082 45.7958 155.568 49.4913L155.663 49.7289L155.532 49.9428L148.771 61.5403L148.296 60.8273L161.759 58.938L161.949 58.9142L162.079 59.0331C165.632 62.3721 170.136 64.7011 174.948 65.6636C176.149 65.9012 177.372 66.0557 178.596 66.1032C179.82 66.2339 181.044 66.1389 182.28 66.1032C184.716 65.8537 187.164 65.4378 189.457 64.5228C191.762 63.6673 193.984 62.5266 195.945 61.0293C196.979 60.3401 197.846 59.4608 198.785 58.6647C199.605 57.7497 200.52 56.9061 201.233 55.8841C204.322 52.0104 206.14 47.2217 206.734 42.326L206.711 42.3498Z"
            fill="#263238"
          />
          <path
            d="M176.838 54.8741L165.359 43.2648L169.221 39.4504L175.923 46.2354L189.849 24.3951L194.424 27.3063L176.838 54.8741Z"
            fill="#C52031"
          />
        </g>
        <defs>
          <clipPath id="clip0_2535_16852">
            <rect width="477.766" height="480" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}

export default LocationErrorIlustrate;
